import { Container, Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem, FormGroup, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane  } from 'reactstrap';
import { userProfileDetailsUpdate, selectProfileUserDetails, isAuthenticated } from './userSlice';
import { validateUserProfileForm } from '../../utils/validateUserProfileForm';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

const UserProfileDetails = ({ toggle, user }) => {
    
    const [open, setOpen] = useState('');
    const [activeTab, setActiveTab] = useState('1');
    const dispatch = useDispatch();
    const userProfileDetails = useSelector(selectProfileUserDetails);
    const auth = useSelector(isAuthenticated);

    const date = new Date(userProfileDetails.misc.dob);
    const dateReformatted = date.toISOString().split('T')[0];

    const toggleAccordion = tabId => {
        if(open === tabId){
            setOpen();
        } else {
            setOpen(tabId);
        }
    };

    const toggleTab = tabId => {
        if(activeTab!==tabId){
            setActiveTab(tabId);
        }
    };

    const initialFormValues = {
        firstname: auth ? userProfileDetails.user.first_name : '',
        lastname: auth ? userProfileDetails.user.last_name : '',
        email: auth ? userProfileDetails.user.email : '',
        dob: auth ? dateReformatted : '',
        phone: auth ? userProfileDetails.mail.phone : '',
        address: auth ? userProfileDetails.mail.street_address : '',
        baddress: auth ? userProfileDetails.bill.street_address: '',
        saddress: auth ? userProfileDetails.ship.street_address : '',
        city: auth ? userProfileDetails.mail.city : '',
        bcity: auth ? userProfileDetails.bill.city : '',
        scity: auth ? userProfileDetails.ship.city : '',
        state: auth ? userProfileDetails.mail.state : '',
        bstate: auth ? userProfileDetails.bill.state : '',
        sstate: auth ? userProfileDetails.ship.state : '',
        zipcode: auth ? userProfileDetails.mail.zip_code : '',
        bzipcode: auth ? userProfileDetails.bill.zip_code : '',
        szipcode: auth ? userProfileDetails.ship.zip_code : ''
    };

    const handleProfileSubmit = (values) => {
        const userProfileDetails = {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            dob: values.dob,
            phone: values.phone,
            address: values.address,
            baddress: values.baddress,
            saddress: values.saddress,
            city: values.city,
            bcity: values.bcity,
            scity: values.scity,
            state: values.state,
            bstate: values.bstate,
            sstate: values.sstate,
            zipcode: values.zipcode,
            bzipcode: values.bzipcode,
            szipcode: values.szipcode,
            userId: user
        };
        dispatch(userProfileDetailsUpdate(userProfileDetails));
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Formik
                        initialValues={initialFormValues}
                        onSubmit={handleProfileSubmit}
                        validate={validateUserProfileForm}>
                        <Form>
                            <Accordion flush open={open} toggle={toggleAccordion}>
                                <AccordionItem>
                                    <AccordionHeader targetId='1'>Personal Information</AccordionHeader>
                                    <AccordionBody accordionId='1'>
                                        <FormGroup>
                                            <Row>
                                                <Col md='6'>
                                                    <Label htmlFor='firstname'>First Name</Label>
                                                    <Field  
                                                        id='firstname'
                                                        name='firstname'
                                                        placeholder={userProfileDetails.user.first_name ||'First Name'}
                                                        className='form-control fieldSpacing'
                                                    />
                                                    <ErrorMessage name='firstname'>
                                                        {msg => <span className='text-danger'>{msg}</span>}
                                                    </ErrorMessage>
                                                </Col>
                                                <Col>
                                                    <Label htmlFor='lastname'>Last Name</Label>
                                                    <Field  
                                                        id='lastname'
                                                        name='lastname'
                                                        placeholder={userProfileDetails.user.last_name || 'Last Name'}
                                                        className='form-control'
                                                    />
                                                    <ErrorMessage name='lastname'>
                                                        {msg => <span className='text-danger'>{msg}</span>}
                                                    </ErrorMessage>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '2rem' }}>
                                                <Col md='4'>
                                                    <Label htmlFor='email'>E-Mail</Label>
                                                    <Field  
                                                        id='email'
                                                        name='email'
                                                        placeholder={userProfileDetails.user.email || 'E-Mail'}
                                                        className='form-control fieldSpacing'
                                                    />
                                                    <ErrorMessage name='email'>
                                                        {msg => <span className='text-danger'>{msg}</span>}
                                                    </ErrorMessage>
                                                </Col>
                                                <Col md='4'>
                                                    <Label htmlFor='phone'>Phone</Label>
                                                    <Field  
                                                        id='phone'
                                                        name='phone'
                                                        placeholder={userProfileDetails.mail.phone || 'Phone'}
                                                        className='form-control fieldSpacing'
                                                    />
                                                    <ErrorMessage name='phone'>
                                                        {msg => <span className='text-danger'>{msg}</span>}
                                                    </ErrorMessage>
                                                </Col>
                                                <Col>
                                                    <Label htmlFor='dob'>Date of Birth</Label>
                                                    <Field  
                                                        id='dob'
                                                        name='dob'
                                                        placeholder={userProfileDetails.misc.dob || 'Date of Birth'}
                                                        type='date'
                                                        className='form-control'
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId='2'>Addresses</AccordionHeader>
                                    <AccordionBody accordionId='2'>
                                        <Nav tabs>
                                            <Col xs='4'>
                                                <NavItem>
                                                    <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggleTab('1')}>
                                                        Mailing Address
                                                    </NavLink>
                                                </NavItem>
                                            </Col>
                                            <Col xs='4'>
                                                <NavItem>
                                                    <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggleTab('2')}>
                                                        Billing Address
                                                    </NavLink>
                                                </NavItem>
                                            </Col>
                                            <Col xs='4'>
                                                <NavItem>
                                                    <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => toggleTab('3')}>
                                                        Shipping Address
                                                    </NavLink>
                                                </NavItem>
                                            </Col>
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId='1'>
                                                <FormGroup>
                                                    <Row>
                                                        <Col style={{ marginTop: '2rem' }}>
                                                            <Label htmlFor='address'>Address</Label>
                                                            <Field  
                                                                id='address'
                                                                name='address'
                                                                placeholder={userProfileDetails.mail.street_address || 'Address'}
                                                                className='form-control'
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md ='4' style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='city'>City</Label>
                                                            <Field  
                                                                id='city'
                                                                name='city'
                                                                placeholder={userProfileDetails.mail.city || 'City'}
                                                                className='form-control'
                                                            />
                                                        </Col>
                                                        <Col md ='4' style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='state'>State</Label>
                                                            <Field  
                                                                id='state'
                                                                name='state'
                                                                className='form-control'
                                                                placeholder={userProfileDetails.mail.state}
                                                                as='select'>
                                                                    <option value=''>{userProfileDetails.mail.state || 'Click to Select'}</option>
                                                                    <option value='AL'>AL</option>
                                                                    <option value='AK'>AK</option>
                                                                    <option value='AZ'>AZ</option>
                                                                    <option value='AR'>AR</option>
                                                                    <option value='CA'>CA</option>
                                                                    <option value='CO'>CO</option>
                                                                    <option value='CT'>CT</option>
                                                                    <option value='DE'>DE</option>
                                                                    <option value='FL'>FL</option>
                                                                    <option value='GA'>GA</option>
                                                                    <option value='HI'>HI</option>
                                                                    <option value='ID'>ID</option>
                                                                    <option value='IL'>IL</option>
                                                                    <option value='IN'>IN</option>
                                                                    <option value='IA'>IA</option>
                                                                    <option value='KS'>KS</option>
                                                                    <option value='KY'>KY</option>
                                                                    <option value='LA'>LA</option>
                                                                    <option value='ME'>ME</option>
                                                                    <option value='MD'>MD</option>
                                                                    <option value='MA'>MA</option>
                                                                    <option value='MI'>MI</option>
                                                                    <option value='MN'>MN</option>
                                                                    <option value='MS'>MS</option>
                                                                    <option value='MO'>MO</option>
                                                                    <option value='MT'>MT</option>
                                                                    <option value='NE'>NE</option>
                                                                    <option value='NV'>NV</option>
                                                                    <option value='NH'>NH</option>
                                                                    <option value='NJ'>NJ</option>
                                                                    <option value='NM'>NM</option>
                                                                    <option value='NY'>NY</option>
                                                                    <option value='NC'>NC</option>
                                                                    <option value='ND'>ND</option>
                                                                    <option value='OH'>OH</option>
                                                                    <option value='OK'>OK</option>
                                                                    <option value='OR'>OR</option>
                                                                    <option value='PA'>PA</option>
                                                                    <option value='PR'>PR</option>
                                                                    <option value='RI'>RI</option>
                                                                    <option value='SC'>SC</option>
                                                                    <option value='SD'>SD</option>
                                                                    <option value='TN'>TN</option>
                                                                    <option value='TX'>TX</option>
                                                                    <option value='UT'>UT</option>
                                                                    <option value='VT'>VT</option>
                                                                    <option value='VA'>VA</option>
                                                                    <option value='WA'>WA</option>
                                                                    <option value='WV'>WV</option>
                                                                    <option value='WI'>WI</option>
                                                                    <option value='WY'>WY</option>
                                                            </Field>
                                                        </Col>
                                                        <Col style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='zipcode'>Zip Code</Label>
                                                            <Field  
                                                                id='zipcode'
                                                                name='zipcode'
                                                                placeholder={userProfileDetails.mail.zip_code || 'Zip Code'}
                                                                className='form-control'
                                                            />
                                                            <ErrorMessage name='zipcode'>
                                                                {msg => <span className='text-warning'>{msg}</span>}
                                                            </ErrorMessage>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </TabPane>
                                            <TabPane tabId='2'>
                                                <FormGroup>
                                                    <Row>
                                                        <Col style={{ marginTop: '2rem' }}>
                                                            <Label htmlFor='baddress'> Billing Address</Label>
                                                            <Field  
                                                                id='baddress'
                                                                name='baddress'
                                                                placeholder={userProfileDetails.bill.street_address || 'Billing Address'}
                                                                className='form-control'
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md ='4' style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='city'>City</Label>
                                                            <Field  
                                                                id='bcity'
                                                                name='bcity'
                                                                placeholder={userProfileDetails.bill.city || 'City'}
                                                                className='form-control'
                                                            />
                                                        </Col>
                                                        <Col md ='4' style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='state'>State</Label>
                                                            <Field  
                                                                id='bstate'
                                                                name='bstate'
                                                                as='select'
                                                                className='form-control'>
                                                                    <option value=''>{userProfileDetails.bill.state || 'Click to Select'}</option>
                                                                    <option value='AL'>AL</option>
                                                                    <option value='AK'>AK</option>
                                                                    <option value='AZ'>AZ</option>
                                                                    <option value='AR'>AR</option>
                                                                    <option value='CA'>CA</option>
                                                                    <option value='CO'>CO</option>
                                                                    <option value='CT'>CT</option>
                                                                    <option value='DE'>DE</option>
                                                                    <option value='FL'>FL</option>
                                                                    <option value='GA'>GA</option>
                                                                    <option value='HI'>HI</option>
                                                                    <option value='ID'>ID</option>
                                                                    <option value='IL'>IL</option>
                                                                    <option value='IN'>IN</option>
                                                                    <option value='IA'>IA</option>
                                                                    <option value='KS'>KS</option>
                                                                    <option value='KY'>KY</option>
                                                                    <option value='LA'>LA</option>
                                                                    <option value='ME'>ME</option>
                                                                    <option value='MD'>MD</option>
                                                                    <option value='MA'>MA</option>
                                                                    <option value='MI'>MI</option>
                                                                    <option value='MN'>MN</option>
                                                                    <option value='MS'>MS</option>
                                                                    <option value='MO'>MO</option>
                                                                    <option value='MT'>MT</option>
                                                                    <option value='NE'>NE</option>
                                                                    <option value='NV'>NV</option>
                                                                    <option value='NH'>NH</option>
                                                                    <option value='NJ'>NJ</option>
                                                                    <option value='NM'>NM</option>
                                                                    <option value='NY'>NY</option>
                                                                    <option value='NC'>NC</option>
                                                                    <option value='ND'>ND</option>
                                                                    <option value='OH'>OH</option>
                                                                    <option value='OK'>OK</option>
                                                                    <option value='OR'>OR</option>
                                                                    <option value='PA'>PA</option>
                                                                    <option value='PR'>PR</option>
                                                                    <option value='RI'>RI</option>
                                                                    <option value='SC'>SC</option>
                                                                    <option value='SD'>SD</option>
                                                                    <option value='TN'>TN</option>
                                                                    <option value='TX'>TX</option>
                                                                    <option value='UT'>UT</option>
                                                                    <option value='VT'>VT</option>
                                                                    <option value='VA'>VA</option>
                                                                    <option value='WA'>WA</option>
                                                                    <option value='WV'>WV</option>
                                                                    <option value='WI'>WI</option>
                                                                    <option value='WY'>WY</option>
                                                            </Field>
                                                        </Col>
                                                        <Col style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='bzipcode'>Zip Code</Label>
                                                            <Field  
                                                                id='bzipcode'
                                                                name='bzipcode'
                                                                placeholder={userProfileDetails.bill.zip_code || 'Zip Code'}
                                                                className='form-control'
                                                            />
                                                            <ErrorMessage name='bzipcode'>
                                                                {msg => <span className='text-warning'>{msg}</span>}
                                                            </ErrorMessage>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </TabPane>
                                            <TabPane tabId='3'>
                                                <FormGroup>
                                                    <Row>
                                                        <Col style={{ marginTop: '2rem' }}>
                                                            <Label htmlFor='saddress'> Shipping Address</Label>
                                                            <Field  
                                                                id='saddress'
                                                                name='saddress'
                                                                placeholder={userProfileDetails.ship.street_address || 'Shipping Address'}
                                                                className='form-control'
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md ='4' style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='scity'>City</Label>
                                                            <Field  
                                                                id='scity'
                                                                name='scity'
                                                                placeholder={userProfileDetails.ship.city || 'City'}
                                                                className='form-control'
                                                            />
                                                        </Col>
                                                        <Col md ='4' style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='sstate'>State</Label>
                                                            <Field  
                                                                id='sstate'
                                                                name='sstate'
                                                                as='select'
                                                                className='form-control'>
                                                                    <option value=''>{userProfileDetails.ship.state || 'Click to Select'}</option>
                                                                    <option value='AL'>AL</option>
                                                                    <option value='AK'>AK</option>
                                                                    <option value='AZ'>AZ</option>
                                                                    <option value='AR'>AR</option>
                                                                    <option value='CA'>CA</option>
                                                                    <option value='CO'>CO</option>
                                                                    <option value='CT'>CT</option>
                                                                    <option value='DE'>DE</option>
                                                                    <option value='FL'>FL</option>
                                                                    <option value='GA'>GA</option>
                                                                    <option value='HI'>HI</option>
                                                                    <option value='ID'>ID</option>
                                                                    <option value='IL'>IL</option>
                                                                    <option value='IN'>IN</option>
                                                                    <option value='IA'>IA</option>
                                                                    <option value='KS'>KS</option>
                                                                    <option value='KY'>KY</option>
                                                                    <option value='LA'>LA</option>
                                                                    <option value='ME'>ME</option>
                                                                    <option value='MD'>MD</option>
                                                                    <option value='MA'>MA</option>
                                                                    <option value='MI'>MI</option>
                                                                    <option value='MN'>MN</option>
                                                                    <option value='MS'>MS</option>
                                                                    <option value='MO'>MO</option>
                                                                    <option value='MT'>MT</option>
                                                                    <option value='NE'>NE</option>
                                                                    <option value='NV'>NV</option>
                                                                    <option value='NH'>NH</option>
                                                                    <option value='NJ'>NJ</option>
                                                                    <option value='NM'>NM</option>
                                                                    <option value='NY'>NY</option>
                                                                    <option value='NC'>NC</option>
                                                                    <option value='ND'>ND</option>
                                                                    <option value='OH'>OH</option>
                                                                    <option value='OK'>OK</option>
                                                                    <option value='OR'>OR</option>
                                                                    <option value='PA'>PA</option>
                                                                    <option value='PR'>PR</option>
                                                                    <option value='RI'>RI</option>
                                                                    <option value='SC'>SC</option>
                                                                    <option value='SD'>SD</option>
                                                                    <option value='TN'>TN</option>
                                                                    <option value='TX'>TX</option>
                                                                    <option value='UT'>UT</option>
                                                                    <option value='VT'>VT</option>
                                                                    <option value='VA'>VA</option>
                                                                    <option value='WA'>WA</option>
                                                                    <option value='WV'>WV</option>
                                                                    <option value='WI'>WI</option>
                                                                    <option value='WY'>WY</option>
                                                            </Field>
                                                        </Col>
                                                        <Col style={{ marginTop: '1rem' }}>
                                                            <Label htmlFor='zipcode'>Zip Code</Label>
                                                            <Field  
                                                                id='szipcode'
                                                                name='szipcode'
                                                                placeholder={userProfileDetails.ship.zip_code || 'Zip Code'}
                                                                className='form-control'
                                                            />
                                                            <ErrorMessage name='szipcode'>
                                                                {msg => <span className='text-warning'>{msg}</span>}
                                                            </ErrorMessage>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </TabPane>
                                        </TabContent>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId='3'>Payment Methods</AccordionHeader>
                                    <AccordionBody accordionId='3'>
                                        <p>Sint id esse ex sint velit mollit proident cillum exercitation cillum. Do eu officia amet amet laboris veniam aliqua elit id occaecat. Fugiat veniam culpa non aute aliquip fugiat minim aute mollit ipsum in et. Ex irure et magna et tempor elit tempor aute esse eu anim ipsum.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId='4'>Purchase History</AccordionHeader>
                                    <AccordionBody accordionId='4'>
                                        <p>Consequat ad in sit et eiusmod exercitation dolore officia dolore sint reprehenderit esse. Commodo non proident elit irure exercitation. Enim officia pariatur incididunt tempor consequat nisi ullamco fugiat cillum. Quis proident non et consequat nulla magna culpa. Irure nisi officia ad sint nisi qui nisi consectetur laborum culpa. Consequat aute enim duis incididunt voluptate ad ad nisi.</p>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                            <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center', margin: '8rem 0rem 3rem' }}>
                                    <Button onClick={toggle} color='secondary' size='lg' style={{ marginRight: '1rem'}}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' color='primary' size='lg' style={{ marginLeft: '1rem'}}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
};

export default UserProfileDetails;