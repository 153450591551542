import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import defaultIcon from '../../app/assets/img/userIcon.jpg';
import { baseUrl } from '../../app/shared/baseUrl';

export const userSignup = createAsyncThunk(
    'user/signup',
    async (newUser, { dispatch, rejectWithValue }) => {
        try{
            const response = await fetch(baseUrl + 'users/signup', {
                method: 'POST',
                body: JSON.stringify(newUser),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if(!response.ok){
                const errorData = await response.json();
                //return Promise.reject('Unable to POST to server. \nStatus: ' + response.status);
                return rejectWithValue(errorData);
            }
            const data = await response.json();
            
            if(data.success){
                dispatch(userLogin(newUser));
            }
            return data;
        } catch(error){
            return rejectWithValue(error.message);
        }
    }
); 

export const userLogin = createAsyncThunk(
    'user/login', 
    async (userLoggingIn, { dispatch, rejectWithValue }) => {
        try{
                const response = await fetch(baseUrl + 'users/login', {
                    method:'POST',
                    body: JSON.stringify(userLoggingIn),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }); 

                if(!response.ok){
                    const errorData = await response.json();
                // return Promise.reject('Could not log in.\nStatus: ' + response.status);
                    return rejectWithValue(errorData);
                }

                const data = await response.json();
                
                dispatch(setCurrentUser(data));

                //const destination = data.path.replaceAll('\\', '/');
                dispatch(userProfilePic(data.token));
                return data;

            } catch(error){
                return rejectWithValue(error.message);
            }
    }
);

export const userProfilePic = createAsyncThunk(
    'user/profilepic',
    async (token) => {
        
        const bearer = 'Bearer ' + token;
        
        const response = await fetch(baseUrl + 'imageUpload', {
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
        });
        
        if(!response.ok){
            return Promise.reject('Could not get picture from server, status: ', response.status);
        }
        
        const data = await response.json();
        
        return data;
    }
);

export const userProfileDetailsRetrieve = createAsyncThunk(
    'user/profileDetails',
    async (userId) => {
        const bearer = 'Bearer ' + localStorage.getItem('token');

        const response = await fetch(baseUrl + `users/${userId}`, {
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
        });

        if(!response.ok){
            return Promise.reject('Could not retrieve User Profile Details!\nStatus: ', response.status);
        }
        const data = await response.json();
        console.log('data in userProfileDetailsRetrieve: ', data);
        return data;
    }
);

export const userLogout = createAsyncThunk(
    'user/logout',
    async () => {
        const bearer = 'Bearer ' + localStorage.getItem('token');
        
        const response = await fetch(baseUrl + 'users/logout', {
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
        });
        //Remove the token on client side no matter what happens with the fetch 
        localStorage.removeItem('token');

        if(!response.ok){
            return Promise.reject('There was an issue logging out on the server side, status: ' + response.status);
        }
        const data = await response.json();
        return data;
    }
);

export const validateLogin = createAsyncThunk(
    'user/validateLogin',
    async ({ dispatch }) => {

        const bearer = 'Bearer ' + localStorage.getItem('token');

        const response = await fetch(baseUrl + 'users/checkJWTtoken', {
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
        });

        if (!response.ok) {
            return Promise.reject('Unable to fetch, status: ' + response.status);
        }
        const data = await response.json();

        if (!data.success) {
            dispatch(clearCurrentUser());
        }

        return data;
    }
);

export const userProfileDetailsUpdate = createAsyncThunk(
    'user/updateProfileDetails',
    async(userProfileDetails) => {

        const bearer = 'Bearer ' + localStorage.getItem('token');

        const response = await fetch(baseUrl + `users/${userProfileDetails.userId}`, {
            method: 'POST',
            body: JSON.stringify(userProfileDetails),
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            }
        });

        if(!response.ok){
            return Promise.reject('Unable to POST to server.\nStatus: ' + response.status);
        }

        const data = await response.json();
        return data;
    }
);

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem('token') ? true : false,
    token: localStorage.getItem('token'),
    currentUser: '',
    profilePic: '',
    profileUserDetails: ''
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload.id;
            if(action.payload.path === null){
                state.profilePic = defaultIcon;
            } else {
                const urlSuffix = action.payload.path.replaceAll('\\', '/');
                state.profilePic = baseUrl + urlSuffix;
            }
        },
        clearCurrentUser: state => {
            state.isLoading = false;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
            state.currentUser = '';
            state.profilePic = ''; 
        }
    },
    extraReducers: {
        [userSignup.pending]: state => {
            state.isLoading = true;
        },
        [userSignup.fulfilled]: (state, action) =>{
            state.isLoading = false;
            state.currentUser = '';
            localStorage.removeItem('token');
            alert(
                action.payload?.status + '\n\n' + action.payload?.id
            );
        },
        [userSignup.rejected]: (state, action) => {
            state.isLoading = false;
            alert(
                'Registration was unsuccessful!\n\n' + 
               (action.payload?.message || action.error.message)
            );
        },
        [userLogin.pending]: state => {
            state.isLoading = true;
            state.currentUser = '';
            localStorage.removeItem('token');
        },
        [userLogin.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.currentUser = action.payload.id;
            localStorage.setItem('token', action.payload.token);
            alert(
                action.payload?.message 
            ); 
        },
        [userLogin.rejected]: (state, action) => {
            state.isLoading = false;
            localStorage.removeItem('token');
            alert(
                'Login Failed!\n\n' + (action.payload?.message || action.error.message)
            );            
        },
        [userLogout.fulfilled]: state => {
            state.isLoading = false;
            localStorage.removeItem('token');
            state.currentUser = '';
        },
        [userLogout.rejected]: state => {
            state.isLoading = false;
            state.currentUser = '';
        },
        [userProfilePic.pending]: state => {
            state.isLoading = true;
            state.currentUser = '';
            state.profilePic = defaultIcon;
        },
        [userProfilePic.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.currentUser = action.payload.id;
            if(action.payload.img_path === null){
                state.profilePic = defaultIcon;
            } else {
                state.profilePic = baseUrl + action.payload.img_path.replaceAll('\\', '/');
            }
        },
        [userProfilePic.rejected]: state => {
            state.isLoading = false;
            state.profilePic = defaultIcon;
        },
        [userProfileDetailsUpdate.pending]: (state) => {
            state.isLoading = true;
        },
        [userProfileDetailsUpdate.fulfilled]: (state, action) => {
            state.isLoading = false;
            console.log('Payload: ', action.payload);
        },
        [userProfileDetailsUpdate.rejected]: (state, action) => {
            state.isLoading = false;
            alert(
                'Profile Update Failed!\n\n' + (action.payload?.message || action.error.message)
            ); 
        },
        [userProfileDetailsRetrieve.pending]: state => {
            state.isLoading = true;
            state.profileUserDetails = '';
        },
        [userProfileDetailsRetrieve.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.profileUserDetails = action.payload;
        },
        [userProfileDetailsRetrieve.rejected]: (state, action) => {
            state.isLoading = false;
            state.profileUserDetails = '';
            alert(
                'Profile Details Retrieved Failed!\n\n' + (action.payload?.message || action.error.message)
            );
        }
    }
});

export const userReducer = userSlice.reducer;

export const { setCurrentUser, clearCurrentUser } = userSlice.actions;

export const isAuthenticated = () => {
    return localStorage.getItem('token') ? true : false;
};

export const selectCurrentUser = state => {
    return state.user.currentUser;
};

export const selectProfilePic = state => {
    return state.user.profilePic;
};

export const selectProfileUserDetails = state => {
    return state.user.profileUserDetails;
};