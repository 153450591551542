import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import UserProfileDetails from './UserProfileDetails';
import { baseUrl } from '../../app/shared/baseUrl';
import { selectCurrentUser, userProfileDetailsRetrieve } from './userSlice';
import UploadButton from '@rpldy/upload-button';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Uploady from '@rpldy/uploady';

const UserProfileModal = ({ isOpen, toggle, profileIcon, onUpdateProfilePic }) => {

    const dispatch = useDispatch();

    const [profilePic, setProfilePic] = useState(profileIcon);

    const userId = useSelector(selectCurrentUser);
    
    const bearer = 'Bearer ' + localStorage.getItem('token');

    useEffect(() => {
        setProfilePic(profileIcon);
        dispatch(userProfileDetailsRetrieve(userId));
    }, [profileIcon, dispatch, userId]);

    const handleUploadComplete = (server) => {

        const responseObj = JSON.parse(server.response);
        
        if(responseObj){
            
            const { filename, destination } = responseObj;
            const imageUrl = destination + '/' + filename;
            
            setProfilePic(imageUrl);
            onUpdateProfilePic(imageUrl);

        } else {
            console.error('Unexpected response stucture: ', server);
        }
    };

    return(
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            id='profileModal'
            size='xl'
            style={{ marginTop: '5rem' }}>
            <ModalHeader toggle={toggle}>
                User Profile Details - {userId}
            </ModalHeader>
            <ModalBody> 
                <Container>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col md='4' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={profilePic} 
                                alt='profile pic' 
                                style={{ 
                                    border: '1px solid #000000', 
                                    width:'100%', 
                                    display: 'flex', 
                                    marginBottom: '1rem'}} /> 
                            <Uploady destination={{ 
                                    url: baseUrl + 'imageUpload',
                                    headers: {
                                        Authorization: bearer
                                    },
                                    credentials: 'same-origin',
                                    filesParamName: "imageFile"
                                    }}
                                    isSuccessfulCall={handleUploadComplete}>
                                <UploadButton text='Update Profile Picture' id='uploadyButton' />
                            </Uploady>
                        </Col>
                        <Col>
                            <UserProfileDetails toggle={toggle} user={userId}/>
                        </Col>
                    </Row>
                </Container>     
            </ModalBody>
        </Modal>
    );
};

export default UserProfileModal;