import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../../app/shared/baseUrl';

export const fetchCampRegisteredUser = createAsyncThunk(
    'camp/fetchCampRegisteredUser',
    async () => {
        const response = await fetch(baseUrl + 'directory/camp');

        if(!response.ok){
            return Promise.reject('Could not fetch registered camp users: ' + response.status);

        }
        const data = await response.json();
        return data;
    }
);

export const postCampRegisterUser = createAsyncThunk(
    'camp/postCampRegisterUser',
    async (registerUser, { rejectWithValue} ) => {
        try{
                const bearer = 'Bearer ' + localStorage.getItem('token');
                
                const response = await fetch(baseUrl + 'directory/camp', {
                                                method: 'POST',
                                                body: JSON.stringify(registerUser),
                                                headers: {
                                                    Authorization: bearer,
                                                    'Content-Type': 'application/json'
                                                },
                                                credentials: 'same-origin'                              
                });
                
                if(!response.ok){
                    const errorData = await response.json()
                    //return Promise.reject('Could not register: ' + response.status);
                    return rejectWithValue(errorData);
                }

                const data = await response.json();
            
                return data;
        }   catch(error){
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    isLoading: true,
    errMsg: '',
    isCampRegistered: false,
    campRegisteredArray: []
};

const campSlice = createSlice({
    name: 'camp',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCampRegisteredUser.pending]: state => {
            state.isLoading = true;
        },
        [fetchCampRegisteredUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.errMsg = '';
            state.campRegisteredArray = action.payload;
        },
        [fetchCampRegisteredUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.errMsg = action.error ? action.error.message: 'Could not fetch registered camp attendees';
        },
        [postCampRegisterUser.pending]: state => {
            state.isLoading = true;
        },
        [postCampRegisterUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCampRegistered = true;
            state.campRegisteredArray.push(action.payload);
            state.errMsg = '';
            alert(
                    `Muchas gracias por registrarse!\n
                    Dios le bendiga ${action.payload.firstname} ${action.payload.lastname}`
            );
        },
        [postCampRegisterUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.errMsg = action.error ? action.payload?.message : 'Was Unable to Register.';
            alert(
                'Registration Failed.\n\nError:  ' + state.errMsg
            );
        }
    }
});

export const campReducer = campSlice.reducer;