import { selectProfilePic, setCurrentUser, selectCurrentUser } from './userSlice';
import UserProfileModal from './UserProfileModal';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'reactstrap';
import { useState } from 'react';

const UserAvatar = () => {

    const [profileOpen, setProfileOpen] = useState(false);
    const profilePic = useSelector(selectProfilePic);
    const userId = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const toggleProfileModal = () => {
        setProfileOpen(!profileOpen);
    };

    const updateProfilePic = (newProfilePic) => {
        dispatch(setCurrentUser({ id: userId, path: newProfilePic }));
    };

    return(
        <>
            <div style={{ width: '3rem', height: '3rem' }}>
                <NavLink onClick={toggleProfileModal}>
                    <img
                        src={profilePic}
                        alt={'user icon'}
                        style={{ width: '100%', border: '1px solid #FFFFFF' }}
                    />
                </NavLink>
            </div>
            <UserProfileModal 
                isOpen={profileOpen} 
                toggle={toggleProfileModal} 
                profileIcon={profilePic} 
                onUpdateProfilePic={updateProfilePic} />
        </>
        
    );
};

export default UserAvatar;